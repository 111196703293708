<template>
  <div class="list-area">
    <div v-if="showArea === 'list'" class="list-area">
      <el-row :gutter="6">
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.appCategoryId"
            placeholder="应用类型"
          >
            <el-option
              v-for="item in appTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4" v-if="searchData.appCategoryId === 1">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.subType"
            placeholder="子类型"
          >
            <el-option
              v-for="item in appSubTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.releaseType"
            placeholder="上架类型"
          >
            <el-option
              v-for="item in releaseTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.releaseStatus"
            placeholder="上架状态"
          >
            <el-option
              v-show="![1001].includes(item.key)"
              v-for="item in appStatusList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.claimStatus"
            placeholder="审核状态"
          >
            <el-option
              v-for="item in auditTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-input
            size="small"
            placeholder="请输入搜索内容"
            v-model="serachKeyword"
            class="input-with-select"
          >
            <el-select
              size="small"
              slot="prepend"
              v-model="serachKeywordCode"
              placeholder="请选择类型"
            >
              <!-- <el-option label="APPID" value="appId"></el-option> -->
              <el-option label="应用名称" value="title"></el-option>
              <el-option label="应用包名" value="packageName"></el-option>
              <el-option label="应用版本" value="versionName"></el-option>
            </el-select>
          </el-input>
        </el-col>
        <div class="selectData">
          <el-col :span="8">
            <el-row type="flex" align="middle">
              <p style="white-space: nowrap">提交日期：</p>
              <el-date-picker
                size="small"
                type="daterange"
                range-separator="至"
                v-model="searchData.date"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-row>
          </el-col>
          <el-col :span="4"
            ><el-button
              style="margin-left: 20px"
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch()"
              >搜索</el-button
            ></el-col
          >
        </div>
      </el-row>
      <PageTable
        rowKey="releaseId"
        :columns="auditcolumns"
        :tableData="audittableData"
        @page-change="handleChangePage"
      >
        <!-- 上架类型 -->
        <template slot-scope="{ row }" slot="releaseType">
          <span :class="row.releaseType | typeDesc(releaseTypeList)">{{
            row.releaseType | typeDesc(releaseTypeList)
          }}</span></template
        >
        <!-- 上架状态 -->
        <template slot-scope="{ row }" slot="releaseStatus">
          <!-- <el-tag size="small" :type="row.claimStatus | tagType(appStatusList)">
            {{ row.claimStatus | typeDesc(appStatusList) }}
          </el-tag> -->
          <el-tag
            size="small"
            :type="row.shelfStatus | ValueTagType(appStatusList)"
          >
            {{ row.shelfStatus }}
          </el-tag>
        </template>
        <!-- 认领审核状态 -->
        <template slot-scope="{ row }" slot="claimStatus">
          <el-tag
            size="small"
            :type="row.claimStatus | tagType(claimStatusList)"
            >{{ row.claimStatus | typeDesc(claimStatusList) }}
          </el-tag></template
        >
        <el-table-column
          label="操作"
          width="150px"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="handleOperate('detail', row)"
              v-has="'lookinfo'"
            >
              <span class="colorGreen">应用详情</span>
            </el-button>
            <el-button
              class="ml10"
              type="text"
              v-has="'review'"
              :disabled="!reviewList.includes(row.claimStatus)"
              @click="handleOperate('review', row)"
            >
              <span>审核</span>
            </el-button>
          </template>
        </el-table-column>
      </PageTable>
    </div>
    <Detail
      v-if="showArea === 'detail'"
      operationType="view"
      :detailData="detailData"
      :releaseType="detailData.releaseType"
      :languageList="languageList"
      @cancle="handleCancel('list')"
    />
    <el-dialog
      center
      title="认领审核"
      destroy-on-close
      :visible.sync="dialogAuditVisible"
    >
      <auditModel
        :detailData="detailData"
        :qualificationResult="qualificationResult"
        @submit="handleSubmitAudit"
      ></auditModel>
    </el-dialog>
  </div>
</template>
<script>
import PageTable from "@/components/PageTable.vue";
import Detail from "@/views/business/appCheck/infoCheck/Detail.vue";
import auditModel from "./auditModel.vue";
import {
  appReviewStatusList,
  appTypeList,
  auditTypeList,
  releaseTypeList, //上架类型
  claimStatusList, //认领审核状态
  appStatusList,
  appSubTypeList,
} from "@/common/constant/constant.js";
import {
  getqualificationList, //认领资质信息查询
  savequalificationList, //认领资质保存
  getClaimList,
  queryDetails,
  getLanguageList,
} from "./indexServe.js";
import moment from "moment";
export default {
  name: "claim",
  components: { PageTable, Detail, auditModel },
  mounted() {
    this.init();
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      qualificationResult: [],
      auditTypeList,
      appTypeList,
      releaseTypeList, //上架类型
      claimStatusList, //认领审核状态
      appStatusList,
      appSubTypeList,
      appReviewStatusList,
      languageList: [],
      searchData: {
        date: [start, end],
      }, //认领审核表单数据
      serachKeyword: "",
      serachKeywordCode: "title",
      tableData: {
        list: [],
      },
      audittableData: {}, //认领审核表头数据
      auditcolumns: [
        //认领审核表格数据
        {
          key: "appId",
          label: "APPID",
          width: 100,
          sortable: true,
          fixed: "left",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "title",
          label: "应用名称",
          fixed: "left",
          width: 100,
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "packageName",
          label: "应用包名",
          width: 100,
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "versionName",
          label: "应用版本",
          fixed: "left",
          width: 100,
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "developerName",
          label: "开发者",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "userName",
          label: "应用认领者",
          width: 120,
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          slot: "releaseType",
          label: "上架类型",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          slot: "releaseStatus",
          label: "上架状态",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          slot: "claimStatus",
          label: "审核状态",
          width: 140,
          align: "center",
          "show-overflow-tooltip": true,
        },
      ], //认领审核表格头部
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
      claimdetailData: {},
      dialogAuditVisible: false,
      showArea: "list", // 记录当前显示的区域，列表-list，应用详情-detail
      reviewList: [900], // 可审核的状态列表reviewList
    };
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    async handleOperate(operationType, row) {
      this.operationType = operationType;
      if (["detail"].includes(operationType)) {
        this.showArea = "detail";
        const detailData = await this.getDetail({
          appInfoId: row.appInfoId,
        });
        this.detailData = {
          releaseType: parseInt(row.releaseType),
          appReleaseId: row.releaseId,
          sourceType: row.sourceType, //来源类型(0开发者平台 1运营平台)
          appId: row.appId,
          id: row.id,
          appInfoId: row.appInfoId,
          ...detailData,
        };
      } else if (operationType === "review") {
        this.dialogAuditVisible = true;
        const claimlgData = await getqualificationList(row);
        this.qualificationResult = claimlgData.data;
        this.detailData = { ...row, ...claimlgData };
      }
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleSubmitAudit(formData) {
      const data = {
        appInfoId: this.detailData.appInfoId,
        attachFileId: formData.accessPath,
        ...formData,
      };
      savequalificationList(data).then((res) => {
        if (res && res.code === 0) {
          this.dialogAuditVisible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    handleCancel(showArea) {
      this.showArea = showArea;
    },
    // 后端请求
    // 获取列表
    async getTableList(params) {
      const { date, ...rest } = this.searchData;
      const data = {
        page: 1,
        size: 10,
        ...rest,
        ...params,
      };
      if (date) {
        data.startDate = date[0];
        data.endDate = date[1];
      }
      data[this.serachKeywordCode] = this.serachKeyword;
      const res = await getClaimList(data);
      if (res && res.code === 0) {
        this.audittableData = res.data;
      }
    },
    // 获取详情
    async getDetail(params) {
      const res = await queryDetails(params);
      let detailData = {};
      if (res && res.code === 0) {
        detailData = res.data;
      }
      return detailData;
    },
    // 获取语言列表
    async getLanguageList() {
      const res = await getLanguageList();
      this.languageList = res.data;
    },
    // 其他
    init() {
      this.getTableList();
      this.getLanguageList();
    },
  },
};
</script>
<style scoped lang="scss">
.selectData {
  display: flex;
  align-items: center;
}
.selectData span {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.input-with-select {
  /deep/.el-input-group__prepend {
    background: #fff;
    width: 80px;
  }
}
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
.up {
  color: red;
}
.down {
  color: blue;
}
</style>
