<template>
  <el-form
    class="auditModel"
    ref="formRef"
    :model="formData"
    :rules="rules"
    size="small"
    label-width="130px"
  >
    <el-form-item label="资质信息">
      <div class="qualificationImg">
        <img
          :src="item.filePath"
          alt=""
          v-for="(item, index) in qualificationResult"
          :key="index"
        />
      </div>
    </el-form-item>
    <el-form-item label="审核结果" prop="claimStatus">
      <el-radio-group v-model="formData.claimStatus">
        <el-radio :label="901">通过</el-radio>
        <el-radio :label="902">不通过</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="审核意见" prop="reviewComment">
      <el-input
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 6 }"
        show-word-limit
        maxlength="100"
        v-model="formData.reviewComment"
        suffix-icon="el-icon-date"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="文件上传">
      <UploadFile
        type="other"
        class="ml10"
        @success="uploadSuccess"
        v-model="formData.accessPath"
      ></UploadFile>
      <div class="ml10" v-show="formData.fileName">
        <span>{{ formData.fileName }}</span>
        <i class="el-icon-close cursor ml10" @click="handleRemoveFile()"></i>
      </div>
    </el-form-item>
    <div class="disfr jc ac">
      <el-button size="small" type="primary" @click="handleSubmitAudit"
        >提交</el-button
      >
    </div>
  </el-form>
</template>

<script>
import UploadFile from "@/components/UploadFile.vue";
export default {
  components: { UploadFile },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
    qualificationResult: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {
    this.init(this.detailData);
  },
  data() {
    return {
      formData: {},
      rules: {
        claimStatus: [{ required: true, message: "请选择审核结果" }],
      },
      disabled: false,
    };
  },
  methods: {
    uploadSuccess(file) {
      this.formData.fileId = file.fileId;
      this.formData.fileName = file.fileName;
    },
    handleRemoveFile() {
      this.formData.fileId = "";
      this.formData.fileName = "";
    },
    handleSubmitAudit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.formData);
        }
      });
    },
    init() {
      this.formData = {
        claimStatus: 901,
        reviewComment: "",
        accessPath: "",
        fileId: "",
        fileName: "",
      };
    },
  },
  watch: {
    detailData(data) {
      this.init(data);
    },
  },
};
</script>

<style lang="scss">
.auditModel {
  width: 50%;
  margin: 0 auto;
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
  .qualificationImg {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
